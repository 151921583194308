<template>
  <div class="problem-detail-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item>
          <i class="icon-home"></i>问题中心</Breadcrumb-item>
        <Breadcrumb-item>编辑问题详情</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="commom-panel-one" style="margin-top: 0;">
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="5">
              <Form-item label="上报日期：">
                {{create_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="会员姓名：">
                {{member_name}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="所属分院：">
                {{branch_name}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="上报来源：">
                {{saveForm.source}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="负责部门：">
                {{charge_unit_name}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="问题类型：">
                {{problem_type}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进师：">
                {{booster}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="详细情况：">
                {{detail_info}}
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="解决方案：">
                <Input v-model="saveForm.reslove" type="textarea" :rows="4" placeholder="请输入解决方案"></Input>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="已有附件：">
                <ul>
                  <li v-for="(item, index) in file_list" ref="fileref" :key="index">
                    <a :href="item.url" target="_blank" class="download">{{item.name}}</a>
                    <a href="javascript:;" @click="deleteFile(index)">删除</a>
                  </li>
                </ul>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item>
                <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :on-success="handleSuccess"
                  :format="['doc','docx','pdf']"
                  :on-format-error="handleFormatError"
                  :on-progress = "progress"
                  action="/api/problem/uploadfile"
                  style="display:inline-block; width:300px;">
                  <a href="javascript:;" class="btn-upload">上传附件</a>
                </Upload>
                <div class="upload-list" v-for="item in uploadList" :key="item.name">
                  <template v-if="item.status === 'finished'">
                    {{item.name}}
                    <div class="upload-list-cover">
                      <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                  </template>
                </div>
              </Form-item>
            </Col>
          </Row>
        </Form>
        <div class="submit-box tac">
          <Button type="success" size="large" @click="saveReslove()">保存</Button>
          <Button type="success" size="large" @click="submitReslove()">提交</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import problemService from '@/services/problemService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			saveForm: {
				source: '',
				problem_id: '',
				member_id: '',
				reslove: '',
				file_ids: '',
			},
			create_time: '',
			member_name: '',
			branch_name: '',
			charge_unit_name: '',
			problem_type: '',
			booster: '',
			detail_info: '',
			uploadList: [],
			file_list: [],
			file_ids_new: '',
			file_ids_old: '',
			uploadPercent: 0,
		};
	},
	computed: {
		problemId() {
			return this.$route.params.problem_id;
		},
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		var param = { problem_id: this.problemId };
		problemService.getDetail(param).then((data) => {
			this.create_time = data.create_time;
			this.member_name = data.member_name;
			this.branch_name = data.branch_name;
			this.saveForm.source = data.source;
			this.charge_unit_name = data.charge_unit_name;
			this.problem_type = data.problem_type;
			this.booster = data.booster;
			this.detail_info = data.detail_info;
			this.saveForm.reslove = data.reslove;
			this.file_list = data.file_list;
			let arrId = [];
			for (var i = 0; i < this.file_list.length; i++) {
				arrId.push(this.file_list[i].id);
			}
			this.file_ids_old = arrId.join(',');
		});
		this.saveForm.member_id = this.memberId;
		this.saveForm.problem_id = this.problemId;
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		handleSuccess(res, file, fileList) {
			this.uploadList = fileList;
			let arrId = [];
			for (var i = 0; i < fileList.length; i++) {
				arrId.push(fileList[i].response.file_id);
			}
			this.file_ids_new = arrId.join(',');
		},
		handleRemove(file) {
			// 从 upload 实例删除数据
			const fileList = this.$refs.upload.fileList;
			this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
			let arrId = [];
			for (var i = 0; i < fileList.length; i++) {
				arrId.push(fileList[i].response.file_id);
			}
			this.file_ids_new = arrId.join(',');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 doc、docx 或 pdf 格式的文件。',
				2,
			);
		},
		progress(event) {
			this.uploadPercent = event.percent;
		},
		saveReslove() {
			if (this.uploadPercent < 100 && this.uploadPercent > 0) {
				this.$Message.warning('请等待附件上传成功后再进行操作');
			} else {
				if (!this.file_ids_old) {
					this.saveForm.file_ids = this.file_ids_new;
				} else if (!this.file_ids_new) {
					this.saveForm.file_ids = this.file_ids_old;
				} else {
					this.saveForm.file_ids = this.file_ids_old + ',' + this.file_ids_new;
				}
				problemService.savaReslove(this.saveForm).then(() => {
					this.$Message.warning('解决方案保存成功');
					var param = { problem_id: this.problemId };
					problemService.getDetail(param).then((data) => {
						this.create_time = data.create_time;
						this.member_name = data.member_name;
						this.branch_name = data.branch_name;
						this.saveForm.source = data.source;
						this.charge_unit_name = data.charge_unit_name;
						this.problem_type = data.problem_type;
						this.booster = data.booster;
						this.detail_info = data.detail_info;
						this.saveForm.reslove = data.reslove;
						this.file_list = data.file_list;
						let arrId = [];
						for (var i = 0; i < this.file_list.length; i++) {
							arrId.push(this.file_list[i].id);
						}
						this.file_ids_old = arrId.join(',');
						this.$refs.upload.fileList = [];
						this.uploadList = this.$refs.upload.fileList;
						this.file_ids_new = '';
					});
				});
			}
		},
		deleteFile(index) {
			this.file_list.splice(index, 1);
			let arrId = [];
			for (var i = 0; i < this.file_list.length; i++) {
				arrId.push(this.file_list[i].id);
			}
			this.file_ids_old = arrId.join(',');
		},
		submitReslove() {
			if (this.uploadPercent < 100 && this.uploadPercent > 0) {
				this.$Message.warning('请等待附件上传成功后再进行操作');
			} else {
				if (this.saveForm.reslove.length === 0) {
					this.$Message.warning('请填写解决方案');
					return false;
				}
				if (!this.file_ids_old) {
					this.saveForm.file_ids = this.file_ids_new;
				} else if (!this.file_ids_new) {
					this.saveForm.file_ids = this.file_ids_old;
				} else {
					this.saveForm.file_ids = this.file_ids_old + ',' + this.file_ids_new;
				}
				problemService.savaReslove(this.saveForm).then(() => {
					this.oksubmit();
				});
			}
		},
		oksubmit() {
			var param = { problem_id: this.problemId };
			problemService.submitReslove(param).then(() => {
				this.$router.push({ path: '/base/problem' });
			});
		},
	},
};
</script>

<style lang="css" scoped>
.problem-detail-container .submit-box {
  margin-top: 30px;
}
.problem-detail-container .ivu-btn-success {
  padding: 11px 48px;
  margin: 0 26px;
}
</style>
